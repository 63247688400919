
.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    padding: 1rem 2rem;
  }
  .ck-reset_all:not(.ck-reset_all-excluded *){
      padding: 2rem;
  }
.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    min-height: 400px;
    margin-bottom: 20px;
  }
  