@font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-Black.woff2') format("woff2");
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-ExtraBold.woff2') format("woff2");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'), 
        url('./Pretendard-Bold.woff2') format("woff2");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-SemiBold.woff2') format("woff2");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-Medium.woff2') format("woff2");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-Regular.woff2') format("woff2");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-ExtraLight.woff2') format("woff2");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-Light.woff2') format("woff2");
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Pretendard";
    src: local('Pretendard'),
        url('./Pretendard-Thin.woff2') format("woff2");
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  } 

  * {
    font-family: "Pretendard";
  }